:root {
  --betterptblack: #333333;
  --cauldronblack: #4d4d4d;
  --charcoalgray: #717171;
  --cityblock: #919191;
  --concretejungle: #b1b1b1;
  --coneyislandsidewalk: #c0c0c0;
  --dust: #e6e6e6;
  --earlysmoke: #f5f5f5;
  --earlysteam: #fafafa;
  --emptiness: #ffffff;
  --dyspnea: #e7ebec;
  --frost: #d9e8ff;
  --fresco: #a4bcda;
  --frenchblue: #4370a9;
  --eveningsky: #2a4e82;
  --krishnamurti: #7343a9;
  --ibizateal: #12a7a3;
  --guajirogreen: #056b59;
  --lightishgreen: #69df86;
  --foamgreen: #93f8a9;
  --fadedgreen: #d5eccc;
  --jaliscowave: #dff5f3;
  --peruvianlily: #ffdf55;
  --pacermaize: #ffcc46;
  --orangepink: #ff6454;
  --messyketchup: #e74c3c;
  --coffee: #6a564e;
  --earth: #a96648;
  --earlgray: #cb7553;
  --englishbreakfast: #e19068;
  --farofa: #e2946d;
  --fawn: #f8aa7e;
  --francisbacon: #f0bca3;
  --munch: #f9c7af;
  --norwegiansalmon: #ffb1a9;
  --lavender: #f0e3ff;
  --papaya: #ffdfdc;
  --playa: #fff5d9;
}

body,
html {
  font-family: Roboto, sans-serif;
  box-sizing: border-box;
  color: var(--betterptblack);
  margin: 0px;
}

button {
  cursor: pointer;
}

/*

  so we can keep tags hierarchical without having to worry about style,
  we will use class names for formatting. The color will be handled by
  the remote config and emotion

*/

.Jumbo {
  font-family: Roboto, sans-serif !important;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.Hero {
  font-family: Roboto, sans-serif !important;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.H1 {
  font-family: Roboto, sans-serif !important;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
}

.H2 {
  font-family: Roboto, sans-serif !important;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.H3 {
  font-family: Roboto, sans-serif !important;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  display: flex;
  align-items: center;
}
.H3 svg {
  width: 22px;
  height: 22px;
  margin-right: 14px;
}

.H4 {
  font-family: Roboto, sans-serif !important;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
}

.Paragraph {
  font-family: Roboto, sans-serif !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.H5 {
  font-family: Roboto, sans-serif !important;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
}

.Body {
  font-family: Roboto, sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}

.H6 {
  font-family: Roboto, sans-serif !important;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
}

.Form {
  font-family: Roboto, sans-serif !important;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}

a {
  text-decoration: none;
}

.MuiInputLabel-formControl {
  white-space: nowrap;
}

.button-or-link {
  border-bottom: 1px solid;
  width: max-content;
  cursor: pointer;
}

.button-or-link:hover,
.button-or-link:focus {
  border-bottom: 1px dotted;
}

#beamerSelector {
  display: none;
}

fieldset {
  border: none;
}
.form-max-width {
  min-width: 482px;
  width: 100%;
  position: relative;
  margin: auto;
}
.form-max-width-btn-wrapper {
  padding-right: 20px;
}

.name-and-contact-info-wrapper {
  height: calc(100vh - 218px);
  width: 100%;
  scrollbar-width: thin;
  overflow-x: hidden;
}
.name-and-contact-info-wrapper::-webkit-scrollbar {
  width: 10px;
}
.name-and-contact-info-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.name-and-contact-info-wrapper::-webkit-scrollbar-thumb {
  background: #c0c0c0;
}
.name-and-contact-info-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.disabled-btn-style {
  top: -8px !important;
  right: 20px !important;
}

.activeButtonNone:focus {
  border: none !important;
  outline: none !important;
  background: none !important;

}
.activeButtonNone:hover {
    background-color: #919191;
}